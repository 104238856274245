import React, { Component, Fragment } from 'react';
import { ContentTitle, FlexLine, Input, Select } from '../../style';
import { Switch } from 'antd';
import { toast } from 'react-toastify';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import Icon from '../../components/Icon';
import { getProductsDetail } from '../../api';
import { message } from 'antd';
@inject('createStore', 'viewStore')
@observer
class EditH5Product extends Component {

  code_list = {
    "1": "$",//C端-北美
    "2": "£",//C端-英国
    "3": "€",//C端-西班牙
    "4": "AUD$",//C端-澳洲
    "5": "€",//C端-德国
    "6": "$",//C端-黑人
    "7": "€",//C端-意大利
    "8": "€",//C端-法国
    "12": "$",//C端-墨西哥
    "13": "€",//C端-葡萄牙
    "15": "CLP$",//C端-智利
    "16": "R$",//C端-巴西
    "17": "¥",//C端-日本
    "18": "lei",//C端-罗马尼亚
    "19": "zł",//C端-波兰
    "21": "€",//C端-荷兰
    "22": "kr",//C端-瑞典
    "23": "CAD$",//C端-加拿大
    "24": "€",//C端-希腊
    "25": "€",//C端-爱尔兰
    "55": "$",//B端
    "56": "$",//珠宝
    '20': ' ر.س'//C端-阿拉伯
  }

  style_lang_list = {
    "1": "Style:",//C端-北美
    "2": "Style:",//C端-英国
    "3": "Modelo:",//C端-西班牙
    "4": "Style:",//C端-澳洲
    "5": "",//C端-德国
    "6": "Style:",//C端-黑人
    "7": "Modello:",//C端-意大利
    "8": "Modèle:",//C端-法国
    "12": "Modelo:",//C端-墨西哥
    "13": "Estilo:",//C端-葡萄牙
    "15": "Modelo:",//C端-智利
    "16": "Modelo:",//C端-巴西
    "17": "",//C端-日本
    "18": "",//C端-罗马尼亚
    "19": "",//C端-波兰
    "21": "Stijl:",//C端-荷兰
    "22": "",//C端-瑞典
    "23": "",//C端-加拿大
    "24": "στυλ:",//C端-希腊
    "25": "",//C端-爱尔兰
    "55": "Style:",//B端
    "56": "Style:",//珠宝
    "20": 'رقم موديل:',//C端-阿拉伯
  }

  host_list = {
    "1": "https://www.firmoo.com",//C端-北美
    "2": "https://www.firmoo.co.uk",//C端-英国
    "3": "https://www.firmoo.es",//C端-西班牙
    "4": "https://www.firmoo.com.au",//C端-澳洲
    "5": "https://www.firmoo.de",//C端-德国
    "6": "https://www.firmoo.com",//C端-黑人
    "7": "https://www.firmoo.it",//C端-意大利
    "8": "https://www.firmoo.fr",//C端-法国
    "12": "https://www.firmoo.com.mx",//C端-墨西哥
    "13": "https://www.firmoo.pt",//C端-葡萄牙
    "15": "https://www.firmoo.cl",//C端-智利
    "16": "https://www.firmoo.com.br", //C端-巴西
    "17": "https://www.firmoo.jp", //C端-日本
    "18": "https://www.firmoo.ro", //C端-罗马尼亚
    "19": "https://www.firmoo.pl", //C端-波兰
    "21": "https://www.firmoo.co.nl",//C端-荷兰
    "22": "https://www.firmoo.se",//C端-瑞典
    "23": "https://www.firmoo.ca",//C端-加拿大
    "24": "https://www.firmoo.gr",//C端-希腊
    "25": "https://www.firmoo.ie",//C端-爱尔兰
    "55": "https://www.firmoo.com",//B端
    "56": "https://www.firmoo.com",//珠宝
    "20": 'https://sa.firmoo.com',//C端-阿拉伯
  }

  state = {
    product_id: this.props.product_id,
    product_data: {},
    color_option: [],
    site: 2,
  }
  handleProductId = (e) => {
    this.setState({
      product_id: e.target.value
    })
  }

  searchProductId = () => {
    const { siteId, updateDataProps } = this.props.createStore
    let product_id = this.state.product_id || this.props.product_id

    message.loading(`loading...`, 0);

    getProductsDetail(product_id, siteId).then((arg) => {
      if (arg.error === 0) {
        const product_data = arg.data
        updateDataProps('product_id', product_id)
        let color_option = []
        product_data.products_color_relation.forEach(element => {
          color_option.push({
            value: element.color_id,
            label: `${element.color_name}(${element.color_number})`
          })
        });
        this.setState({
          product_data: product_data,
          products_color_relation: product_data.products_color_relation,
          color_option: color_option
        })
        product_data.products_color_relation[0] && this.handColorSelect(product_data.products_color_relation[0].color_id)
        message.destroy()
      } else {
        toast("商品查询失败，请确认商品ID正确", {
          type: 'error'
        });
        message.destroy()
      }

    }).catch(() => {
      toast("商品查询失败，请确认商品ID正确", {
        type: 'error'
      });
      message.destroy()
    })
  }

  handColorSelect = (color_id) => {
    const { updateDataProps, siteId } = this.props.createStore
    let products_color_relation = this.state.products_color_relation
    let colorData = products_color_relation.find((item) => item.color_id == color_id)
    let glasses = '';
    switch (Number(this.state.product_data.master_categories_id)) {
      case 1:
        glasses = 'eyeglasses';
        break;
      case 3:
        glasses = 'goggles';
        break;
      case 4:
      case 9:
        glasses = 'goggles';
        break;
      case 6:
      case 7:
        glasses = 'sunglasses';
        break;

      default:
        glasses = 'eyeglasses';
    }

    if (colorData) {
      const { products_id, color_id, color_image, color_number } = colorData
      let imgSrc = `https://df5apg8r0m634.cloudfront.net/p/${products_id}/middle-${this.state.site}-${color_image}`
      updateDataProps('imgSrc', imgSrc)
      updateDataProps('color_id', color_id)
      updateDataProps('color_image', color_image)
      updateDataProps('href', `${this.host_list[siteId] ? this.host_list[siteId] : ''}/${glasses}-p-${products_id}.html?color=${color_id}`)
      updateDataProps('product_style', `${this.style_lang_list[siteId] ? this.style_lang_list[siteId] : ''} `)
      updateDataProps('product_name', `${this.state.product_data.products_description_relation.products_name_attribute || this.state.product_data.products_model}`)
      const showprice = showPrice(colorData.product_price);
      let code = this.code_list[siteId]
      let price = showprice.price
      let pre_price = colorData.product_price.price

      price = calcCurrencies(price, code, siteId)
      pre_price = calcCurrencies(pre_price, code, siteId)


      updateDataProps('price', price)
      if (showprice.showDiscount) {
        updateDataProps('pre_price', pre_price)
      } else {
        updateDataProps('pre_price', '')
      }
    }

  }

  render() {
    const {
      code = '$',
      href,
      btn_styles = {},
      btn_color = '#000',
      btn_text = 'Shop Now >',
      product_id,
      color_id,
      imgSrc,
      color_image,
      btn_size = '',
      isShowPrice = true,
      isShowSide = false, // 原有的侧面图展示判断
      isShowName = true,
      isShowBtn = true,
      isBorderBtn = false
    } = this.props;


    const { updateDataProps } = this.props.createStore;
    return (
      <div style={{ paddingRight: '10px' }}>
        <ContentTitle>输入产品id获取产品信息：</ContentTitle>
        <SearchContainer justify="flex-start">
          <SearchInput
            value={this.state.product_id}
            onChange={this.handleProductId}
            placeholder={'输入产品id'}
            style={{ width: '100%', boxSizing: 'border-box' }}
          />
          <SearchBtn onClick={this.searchProductId}>
            <Icon icon={'icon-search'} style={{ color: '#26ABFF' }} />
          </SearchBtn>
        </SearchContainer>

        {this.state.color_option.length > 0 && <div style={{ paddingRight: '10px', marginBottom: '20px' }}>
          <ContentTitle>选择产品色号：</ContentTitle>
          <Select
            placeholder={'选择产品色号'}
            value={color_id}
            onChange={(e) => {
              this.handColorSelect(e.target.value)
            }}
          >
            <option value={''}>请选择</option>
            {
              this.state.color_option.map((item, index) => (
                <option key={index} value={item.value}>{item.label}</option>
              ))
            }
          </Select>
        </div>}

        <SwitchLine>
          <SwitchTitle>显示价格：</SwitchTitle>
          <Switch checked={isShowPrice} onChange={(checked) => {
            updateDataProps('isShowPrice', checked)
          }} />
        </SwitchLine>
        <SwitchLine>
          <SwitchTitle>显示型号色号名:</SwitchTitle>
          <Switch checked={isShowName} onChange={(checked) => {
            updateDataProps('isShowName', checked)
          }} />
        </SwitchLine>
        <SwitchLine>
          <SwitchTitle>显示按钮:</SwitchTitle>
          <Switch checked={isShowBtn} onChange={(checked) => {
            updateDataProps('isShowBtn', checked)
          }} />
        </SwitchLine>
        <SwitchLine>
          <SwitchTitle>边框按钮:</SwitchTitle>
          <Switch checked={isBorderBtn} onChange={(checked) => {
            updateDataProps('isBorderBtn', checked)
          }} />
        </SwitchLine>
        {/* <SwitchLine>
                    <SwitchTitle>侧面图:</SwitchTitle>
                    <Switch checked={isShowSide} onChange={(checked) => {
                        updateDataProps('isShowSide', checked)
                        let imgurl = '';
                        if(imgSrc){
                            imgurl = `https://df5apg8r0m634.cloudfront.net/p/${product_id}/middle-${checked ? 1 : 2}-${color_image}`
                            updateDataProps('imgSrc', imgurl)
                        }
                        this.setState({
                            site:checked ? 1 : 2
                        })
                    }} />
                </SwitchLine> */}
        <div style={{ marginBottom: "20px" }}>
          <ContentTitle>平铺图展示方式选择:</ContentTitle>
          <Select
            placeholder={'选择产品色号'}
            value={imgSrc}
            onChange={(e) => {
              updateDataProps('imgSrc', `${e.target.value}`)
            }}
          >
            {
              [{
                value: `https://df5apg8r0m634.cloudfront.net/p/${product_id}/middle-2-${color_image}`,
                label: '正面图'
              }, {
                value: `https://df5apg8r0m634.cloudfront.net/p/${product_id}/middle-1-${color_image}`,
                label: '侧面图'
              }, {
                value: `https://df5apg8r0m634.cloudfront.net/p/${product_id}/middle-4-${color_image}`,
                label: '平铺图'
              }
              ].map((item, index) => (
                <option key={index} value={item.value}>{item.label}</option>
              ))
            }
          </Select>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <ContentTitle>按钮文案：</ContentTitle>
          <Input
            value={btn_text}
            placeholder={'输入按钮文案'}
            onChange={(e) => updateDataProps('btn_text', `${e.target.value}`)}
            style={{ width: '100%', boxSizing: 'border-box' }}
          />
        </div>

        <div style={{ paddingRight: '10px', marginBottom: '20px' }}>
          <ContentTitle>选择按钮大小：</ContentTitle>
          <Select
            placeholder={'选择产品色号'}
            value={btn_size}
            onChange={(e) => {
              updateDataProps('btn_size', `${e.target.value}`)
            }}
          >
            <option value={''}>请选择</option>
            {
              [{
                value: 'lg',
                label: '大号'
              }, {
                value: 'md',
                label: '中号'
              }, {
                value: 'ms',
                label: '小号'
              }, {
                value: 'auto',
                label: '根据文字长度展示'
              }
              ].map((item, index) => (
                <option key={index} value={item.value}>{item.label}</option>
              ))
            }
          </Select>
        </div>


        <div style={{ marginBottom: "20px" }}>
          <ContentTitle>按钮颜色：</ContentTitle>
          <FlexLine justify='flex-start'>
            <Input value={btn_color} type="color" name='color'
              onChange={(e) => updateDataProps('btn_color', `${e.target.value}`)}
              style={{ width: '44px', height: '44px', padding: '2px' }} />
            <Input type="text" name='color' placeholder={'选中颜色/输入颜色'} value={btn_color || ''}
              onChange={(e) => updateDataProps('btn_color', `${e.target.value}`)} />
          </FlexLine>
        </div>

        <ContentTitle>跳转链接：</ContentTitle>
        <Input
          value={href}
          placeholder={'输入跳转链接'}
          onChange={(e) => updateDataProps('href', `${e.target.value}`)}
          style={{ width: '100%', boxSizing: 'border-box' }}
        />
      </div>
    )
  }
}

export default EditH5Product;

function showPrice(productPrice) {
  console.log(productPrice, 12);
  //
  // debugger
  const now = Math.floor(Date.now() / 1000);
  switch (true) {
    case !productPrice:
      return {
        price: 0,
        showDiscount: false,
        salePer: 0
      };
    case !productPrice.discount_start || !productPrice.discount_expire:
      // || !productPrice.discount_qty_limit:
      return {
        price: productPrice.price,
        showDiscount: false
      };
    case now > productPrice.discount_start &&
      now < productPrice.discount_expire:
      return {
        price: productPrice.discount_price,
        showDiscount: true
      };
    default:
      return {
        price: productPrice.price,
        showDiscount: false
      };
  }
}
const format = (num) => {
  num = String(num); //数字转字符串
  let str = ''; //字符串累加
  for (let i = num.length - 1, j = 1; i >= 0; i--, j++) {
    if (j % 3 == 0 && i != 0) {
      //每隔三位加逗号，过滤正好在第一个数字的情况
      str += num[i] + '.'; //加千分位逗号
      continue;
    }
    str += num[i]; //倒着累加数字
  }
  return str
    .split('')
    .reverse()
    .join(''); //字符串=>数组=>反转=>字符串
}
const calcCurrencies = (value, code, siteId) => {
  if (typeof value === 'string') value = value.replace(/,/g, '');
  value = parseFloat(value * 100);
  let num = Math.round(value);
  let cents = num % 100;
  cents = cents < 10 ? '0' + cents : cents;
  num = Math.floor(num / 100).toString();
  for (let i = 0; i < Math.floor(num.length - (1 + i) / 3); i++) {
    const a = num.substring(0, num.length - (4 * i + 3));
    if (a) {
      num =
        num.substring(0, num.length - (4 * i + 3)) +
        ',' +
        num.substring(num.length - (4 * i + 3));
    }
  }
  //瑞典
  if (siteId == 22) {
    return `${num} ${code}`;
  }
  //荷兰
  if (siteId == 21) {
    return `${code} ${num},${cents}`;
  }

  //希腊、爱尔兰、波兰
  if (siteId == 24 || siteId == 25 || siteId == 19) {
    return `${num},${cents} ${code}`
  }

  if (siteId == 7) {
    return `${code}${num},${cents}`;
  } else if (siteId == 15) {
    var reg1 = new RegExp(",", "g");
    if (num.toString().length > 3) {
      num = `${num}`.replace(reg1, "");
      num = `${format(num)}`; //千分位加.
    }
    return `${code}${num}`;
  } else if (siteId == 1 || siteId == 2 || siteId == 23) {
    return `${code}${num}.${cents}`
  }else if(siteId == 20){
    code = code + ' ';
    return `${num}.${cents}${code}`
  } else {
    return `${num},${cents}${code}`
  }
}

const SearchContainer = styled.div({
  border: '1px solid #e4e4e4',
  background: '#fff',
  borderRadius: '4px',
  display: 'flex',
  marginBottom: '20px'
})

const SearchInput = styled.input({
  flex: 1,
  padding: '0 10px',
  lineHeight: '38px',
  border: 'none'
})

const SearchBtn = styled.div({
  padding: '0 10px',
  height: '38px',
  display: 'flex',
  alignItems: 'center'
})

const SwitchLine = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px'
})

const SwitchTitle = styled.div({
  fontSize: '14px',
  color: '#333',
  width: '120px'
})