import mockData from './mockData';

const defItem = {
  imgSrc: 'https://df5apg8r0m634.cloudfront.net/images/5610fe3aa56a462b8e575a9eb2dee686.png',
  name: 'User Name1',
  text: 'It is a long established fact that a reader will be distrvacted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal,It is a long established fact that a reader will be distrvacted by the readable content of a page when looking at its layout.',
  href: ''
}
const pageCls = 'user-show-page';
const wrapCls = 'user-show-swiper';

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  let { userList = mockData, autoPlay, cardBgColor = '#fff', color } = props;

  const result = {
    styles: [
      {
        name: `.pt-h5-user-show-${page_id}`,
        content: `
          .pt-h5-user-show-${page_id} {
            padding-left: 10px;
            overflow: hidden;
          }
          .pt-h5-user-show-box-${page_id} {
            width: 300px;
            margin-left: unset;
            margin-right: none;
            overflow: visible;
          }
          .pt-h5-swiper-wrap-padding {
            max-width: 300px;
            padding-right: 10px;
            box-sizing: border-box;
          }
          .pt-h5-user-show-a,
          .pt-h5-user-show-a:visited,
          .pt-h5-user-show-a:hover,
          .pt-h5-user-show-a:focus {
            text-decoration: none;
            color: #333;
            background: #fff;
          }
          .pt-h5-user-show-swiper-wrap {
            padding: 15px;
            padding-left: 5px;
            border: 1px solid #262626;
            display: flex;
            height: 180px;
            align-items: flex-start;
            border-radius: 4px;
            box-sizing: border-box;
          }
          .pt-h5-user-show-user {
            text-align: center;
            margin-right: 10px;
            flex-shrink: 0;
          }
          .pt-h5-user-show-avatar {
            width: 68px;
            height: 68px;
            border-radius: 50%;
            margin-bottom: 10px;
          }
          .pt-h5-user-show-name {
            text-align: center;
            font-size: 14px;
            font-family: Roboto-Medium;
            margin: 0;
            max-width: 90px;
          }
          .pt-h5-user-show-text {
            font-size: 12px;
            color: #333;
            text-align: left;
            line-height: 1.35;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 9;
            overflow: hidden;
          }
        `
      },
      {
        name: `.pt-h5-user-show-${id}`,
        content: `
          .pt-h5-user-show-swiper-wrap-${id} {
            background-color: ${cardBgColor || '#fff'};
          }
          .pt-h5-user-show-name-${id},
          .pt-h5-user-show-text-${id} {
            color: ${color || '#333'};
          }
        `
      }
    ],
    html: `
      <link rel="stylesheet" href="https://df5apg8r0m634.cloudfront.net/react/swiper.css"></link>
      <div class="pt-h5-user-show-${page_id} pt-h5-user-show-${id}">
        <div class="pt-h5-user-show-box-${page_id} pt-h5-user-show-box-${id}">
          <div class="swiper-wrapper">
            ${userList.map(item => {
      const { imgSrc, text, name, link } = item
      return `
                <div class="pt-h5-swiper-wrap-padding swiper-slide">
                  <a class="pt-h5-user-show-a" ${link ? `href="${link}"` : ''}>
                    <div class="pt-h5-user-show-swiper-wrap pt-h5-user-show-swiper-wrap-${id}">
                      <div class="pt-h5-user-show-user">
                        <img class="pt-h5-user-show-avatar" src="${imgSrc}" />
                        <div class="pt-h5-user-show-name pt-h5-user-show-name-${id}">${name}</div>
                      </div>
                      <div class="pt-h5-user-show-text pt-h5-user-show-text-${id}">${text}</div>
                    </div>
                  </a>
                </div>
              `
    }).join(' ')}
          </div>
        </div>
      </div>
      <script>
        (function(){
          const script = document.createElement('script')
          script.onload = function(){
            new Swiper('.pt-h5-user-show-box-${id}', {
              loop: ${autoPlay ? 'true' : 'false'},
              slidesPerView: 1,
              autoplay: ${autoPlay ? `{ delay: 3000, stopOnLastSlide: false, disableOnInteraction: false }` : 'false'},
            })
          }
          script.src = 'https://df5apg8r0m634.cloudfront.net/react/swiper.js'
          document.body.appendChild(script)
        })()
      </script>
    `,
  }
  return result
}
