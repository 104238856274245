import { toLine } from "../../../utils/transformCssPropertyName"

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  let { autoPlay, slidesPerView, styles = {}, isEdit } = props

  const result = {
    styles: [
      {
        name: `.pt-h5-swiper-${page_id}`,
        content: `
          .pt-h5-swiper-${page_id} {
            position: relative;
            overflow: hidden;
            margin: 0 auto;
            height: 0px;
          }
          .pt-h5-swiper-${page_id}.show {
            height: auto;
          }
          .swiper-page-div {
            left: 50%;
            padding-top: 20px;
            transform: translate3d(-50%, 0, 0) !important;
          }
          .swiper-page-div > span {
            width: 16px;
            height: 3px;
            margin-right: 16px;
            border-radius: 2px;
          }
          .swiper-page-div > span:focus {
            outline: none;
          }
          .swiper-page-div .swiper-pagination-bullet-active {
            width: 24px;
          }
          .pt-h5-swiper-box {
            width: 100%;
            padding-bottom: 40px !important
          }
          .pt-h5-swiper-wrapper.is-edit {
            flex-wrap: wrap;
          }
        `
      },
      {
        name: `.pt-h5-swiper-${id}`,
        content: `
          .pt-h5-swiper-${id} {
            ${Object.keys(styles).map(css => {
          return `${toLine(css)}:${styles[css]};`
        }).join(' ')}
          }
        `
      },
    ],
    html: `
      <link rel="stylesheet" href="https://df5apg8r0m634.cloudfront.net/react/swiper.css"></link>
      <div class="pt-h5-swiper-${page_id} pt-h5-swiper-${id}">
        <div class="pt-h5-swiper-box pt-h5-swiper-box-${id}">
          <div class="pt-h5-swiper-wrapper swiper-wrapper ${isEdit ? 'is-edit' : ''}">
            {{CHILDREN_PLACEHOLDER}}
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <div class="swiper-page-div swiper-pagination"></div>
      </div>
      <script>
        (function(){
          let classElement = document.querySelector('.pt-h5-swiper-${id}')
          if (!classElement) {
            return
          }
          const script = document.createElement('script')
          script.onload = function(){
            new Swiper('.pt-h5-swiper-box-${id}', {
              autoplay: ${autoPlay ? `{ delay: 3000, stopOnLastSlide: false, disableOnInteraction: false }` : 'false'},
              loop: false,
              slidesPerView: ${slidesPerView},
              pagination: {
                el: '.swiper-pagination',
                clickable: true
              }
            })
            classElement.classList.add('show')
          }
          script.src = 'https://df5apg8r0m634.cloudfront.net/react/swiper.js'
          document.body.appendChild(script)
        })()
      </script>
    `,
  }
  return result
}
